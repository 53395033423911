.manufacturingCard {
  width: 678px;
}

.radio-form-wrapper {
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #369efb;
    border-color: #369efb;
  }

  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-secondary {
    color: #8a8a8a;
    background-color: #fff;
    border-color: #d2d2d2;
  }
  .btn-secondary:focus,
  .btn-secondary.focus {
    box-shadow: none;
  }

  .line-wrapper {
    margin-top: 2%;
    .col-sm-3 {
      text-align: -webkit-right;
      .btn-group {
        width: 84%;
      }
    }
  }

  .MuiButton-text {
    padding: 6px 0px;
  }
  .previous-button-common {
    border-color: #d2d2d2 !important;
    width: 84%;
    color: #8a8a8a;
    padding: 9px 0;
    .number-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .icon-number {
        height: 1rem;
        width: 1rem;
        cursor: pointer;
      }
    }
  }

  .col-sm-9 {
    margin: auto;
    font-size: 14px;
    color: #333333;
  }
}

.back-cutton-display {
  display: none !important;
}
