.sellerPolicy-title {
  font-size: 18px;
  font-weight: normal;
  margin: 0px 0 22px;
}

.seller-amount {
  font-size: 40px;
  margin: 2px;
  .dollar-sign-sellerPage {
    font-size: 20px;
  }
}

.per-month {
  font-size: 12px;
  font-weight: 600;
  margin: 0 0 32px;
  color: #8a8a8a;
}

.input-box-icon {
  margin-bottom: 22px;
  .input-group-text {
    background-color: transparent;
    border-right: 0 !important;
    border: 2px solid #d2d2d2;
    padding: 0rem 0.75rem;
  }
  .form-control {
    border: 2px solid #d2d2d2;
    border-left: 0 !important;
    height: 42px;
    font-size: 18px;
    &::placeholder {
      color: #d2d2d2;
    }
  }
  .MuiSvgIcon-root {
    fill: #d2d2d2;
  }
}

.sellerPage-input {
  border-radius: 4px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

// .was-validated .form-control:invalid:focus,
// .form-control.is-invalid:focus .sellerPage-input  {
//   box-shadow: 4px 0px 0px 0.2rem rgba(220, 53, 69, 0.25) !important;
// }

// .first-page-input-form {
//   .was-validated .form-control:invalid:focus,
//   .form-control.is-invalid:focus {

//   }
// }

.form-control:focus {
  box-shadow: none;
}

.get-quotes-button {
  height: 42px;
  width: 100%;
  background-color: #145c9e !important;
  font-family: "Nunito" !important;
}

.bottom-text-container {
  margin-top: 32px;
  text-align: justify;
}

.last-paragraph {
  margin-top: 18px;
}

.sigCanvas {
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  margin-bottom: 18px;
}

.error-message {
  border: 2px solid #dc3545 !important;
  border-right: 0px !important;
}

.success-message {
  border: 2px solid #28a745 !important;
  border-right: 0px !important;
}

.sellerpage-form-wrapper {
  height: 60px;
  display: flex;
  border: 2px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  .left-side-image {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 35%;
    padding: 9px;
    height: 56px;
    img {
      height: 22px;
      margin-top: 10px;
    }
    span {
      font-size: 14px;
      margin-top: 4px;
    }
  }
  .autoselect-dorpdown-wrapper {
    position: relative;
    width: 65%;
    .MuiFormControl-marginNormal {
      margin: 0;
    }
    input {
      color: #8a8a8a;
      font-size: 16px;
    }
    .expanded-svg {
      position: absolute;
      top: 17px;
      right: 28px;
    }
  }
}

.live-homepage {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 3px 12px;
  cursor: pointer;
  border-radius: 4px;
  span {
    font-size: 12px;
  }
  .next-button-common {
    margin-top: 24px;
    span {
      color: white !important;
      font-size: 16px;
    }
  }
  .modal {
    display: block;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    label {
      text-align: initial;
    }
    input {
      height: 40px;
    }
  }

  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    top: 26%;
    padding: 10px 20px 20px 20px;
    border: 1px solid #888;
    width: 32%;
  }

  /* The Close Button */
  .close {
    color: #aaaaaa;
    font-size: 28px;
    font-weight: bold;
    text-align: right;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}

.live-other-page {
  display: flex;
  cursor: default !important;
  align-items: center;
  .dot-behind-live {
    height: 6px;
    width: 7px;
    border-radius: 15px;
    margin-right: 6px;
  }
}
.inactive-dot-behind-live {
  background-color: #8a8a8a;
}
.active-dot-behind-live {
  background-color: #24be5c;
}

.inactive-live-other-page {
  span {
    color: #8a8a8a;
  }
}
.activate-live-other-page {
  span {
    color: #24be5c;
  }
}

.activate-live-homepage {
  background-color: #d7f7e2;
  span {
    color: #24be5c;
  }
}

.inactive-live-homepage {
  background-color: #ebebeb;
  span {
    color: #8a8a8a;
  }
}

.popover-body{
  display: flex;
    justify-content: space-between;
}
