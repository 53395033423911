.main-containor {
  background-color: #f7f7fb;
  font-family: Nunito;
}

body {
  height: 100%;
  margin: 0;
  font-family: Nunito !important;
  button:focus {
    outline: 0;
  }
}

.header {
  background-color: transparent;
  height: 60px;
  display: flex;
  .logo-image {
    // padding: 16px 43px;
    position: absolute;
    top: 2%;
    margin: auto 20px;
  }
  .demo-logo-image {
    position: absolute;
    top: 2%;
    right: 0;
    margin: auto 20px;
  }

  .header-title {
    h3 {
      margin: 0;
    }
    justify-content: center;
    width: 100%;
    display: flex;
    top: 2.1%;
    position: absolute;
  }
}

.main-containt-container {
  // display: flex;
  // align
  min-height: calc(100vh - 124px);
  width: 532px;
  text-align: center;
  margin: 0 auto;
  // height: 89vh;
  .side-nav-container {
    // width: 20%;
    background-color: white;
  }
  .main-containt {
    background-color: white;
    padding: 30px;
    margin: 26px auto;
    box-shadow: 0px 0px 7px 1px #f1f1f1;
    border-radius: 4px;
    // width: 80%;
    position: relative;
  }

  .MuiStepper-root {
    display: flex;
    padding: 18px 22px;
  }

  .stepper-container {
    .MuiStepper-alternativeLabel {
      background-color: transparent;
      // .MuiStepper-root {
      //   display: flex;
      //   padding: 14px 22px;
      // }
      .MuiStepIcon-root {
        color: #e8f4ff;
        height: 20px;
        width: 20px;
        .MuiStepIcon-text {
          fill: #369efb;
        }
      }
      .MuiStepIcon-root.MuiStepIcon-active {
        color: #369efb !important;
        .MuiStepIcon-text {
          fill: white;
        }
      }
      .MuiStepIcon-root.MuiStepIcon-completed {
        color: #369efb !important;
        .MuiStepIcon-text {
          fill: white;
        }
      }
      .MuiStepConnector-alternativeLabel {
        top: 9px;
        left: calc(-50% + 10px);
        right: calc(50% + 10px);
        .MuiStepConnector-lineHorizontal {
          border-top-width: 2px;
          border-color: #e2e2e2;
        }
      }
      .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 6px;
        font-weight: 400;
      }
      .MuiStepLabel-label.MuiStepLabel-active {
        color: #369efb;
      }
      .MuiStepLabel-label {
        color: #8a8a8a;
      }
      .MuiStepLabel-label.MuiStepLabel-completed {
        color: #369efb;
      }
      .MuiTypography-body2 {
        font-size: 12px;
      }
    }
  }
}

.stepper-wrapper {
  min-height: unset !important;
}

.footer-container {
  background-color: rgba(138, 138, 138, 0.1);
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tc-and-pp-container {
    display: flex;
    padding-right: 26px;
    .breadcrumbs {
      border-left: 1.5px solid rgba(138, 138, 138, 0.33);
      margin: 0 12px;
    }
  }
  .copyright-text {
    padding: 6px 43px;
  }
  span {
    font-size: 13px;
    color: #8a8a8a;
  }
}

.paragraphText {
  color: #8a8a8a;
  font-size: 12px;
}

.paragraph-hading {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.page-title-hading {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  margin: 0;
}

.next-button-common {
  height: 42px;
  width: 110px;
  font-family: Nunito !important;
  background-color: #145c9e !important;
  border-radius: 3px;
}

.previous-button-common {
  background: #ffffff !important;
  height: 42px;
  width: 110px;
  font-family: Nunito !important;
  border: 1px solid #aabacd !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
}

.bottom-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
}

.productTableCard {
  width: 900px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
