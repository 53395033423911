.slider-container {
  .MuiSlider-root {
    color: #369efb;
  }
  .MuiSlider-rail {
    height: 3px;
    color: #f3f3f3;
  }
  .MuiSlider-mark {
    width: 10px;
    height: 10px;
    top: 9px;
    border-radius: 10px;
    color: #e8f4ff;
    display: none;
  }
  .MuiSlider-thumb {
    width: 17px;
    height: 17px;
    margin-top: -7px;
    box-shadow: 0px 0px 0px 8px rgba(54, 158, 251, 0.22);
  }
  .MuiSlider-track {
    height: 3px;
    color: #369efb;
  }
}

.startDate-container {
  text-align: initial;
  margin-top: 30px;
}

.slider-wrapper {
  margin-top: 28px;
  padding: 0 11px;
}

.form-label {
  font-size: 14px;
}

.policy-amount {
  .dollar-sign {
    font-size: 20px;
  }
  .million-text {
    font-size: 16px;
    margin-left: 2%;
  }
  margin-top: 38px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  height: 15px;
  width: 15px;
  // cursor: grabbing;
  // background: url("../../Assets/linux-icon.png");

  // border-width: thin;
  // background-color: blue;
  color: pink;
  opacity: 1;
  display: block;
}

.my-qutes-title {
  margin-bottom: 16px !important  ;
}

.my-qutes-amount {
  font-size: 42px;
  text-align: -webkit-center;
  .doller-sign {
    font-size: 20px;
  }
}

.my-qutes-month {
  margin: 0 0 19px;
}

.get-discount {
  margin-top: 12px !important;
}

.blankPolicy-table {
  position: absolute;
  top: 42%;
  left: 44%;
}

.policy-table-placeholder {
  height: 31vh;
}
